import { defaultPackageBlockData, PackageBlockTemplate } from '../package-block/c-package-block.template'
import { defaultPriceData, PriceTemplate, getACMPriceLabel } from '../price/c-price.template'
import { defaultButtonData, BtnTemplate } from '../btn/c-btn.template'
import { SliderTemplate } from '../slider/c-slider.template'
import { LabelTemplate } from '../label/c-label.template'
import { ImgTemplate } from '../../components/img/c-img.template'
import { getTokenClass } from '../../../js/helpers/tokens'
import { getProcessedCollectionDataFromItem } from '../../../js/helpers/collection-mapper'
import { BreadcrumbsTemplate, defaultBreadcrumbsData } from '../breadcrumbs/c-breadcrumbs.template'
import { encodeAttributes } from '../../../js/helpers/string'
import { getFooter } from '../product-card/c-product-card.template'

/**
 * The SearchResultData contains all data needed to hydrate a SearchResult view
 *
 * @typedef {Object}          SearchResultData
 *
 * @property {String}         id                              - The result id
 * @property {Boolean}        isFavorite                      - The result is included to favorites or not
 * @property {Boolean}        isUnavailable                   - The result is unavailable or not
 * @property {Boolean}        isFlightOnly                    - The result is flight only or not
 * @property {Boolean}        isHighlighted                   - The result is highlighted or not
 * @property {String[]}       images                          - The result image url
 * @property {String}         name                            - The accommodation name
 * @property {String[]}       usps                            - The accommodation usp's
 * @property {String}         urlWithFilters                  - The accommodation URL with applied search filters
 * @property {Object}        [collection]                     - The collection name associated with the result
 * @property {Number}        [userRating]                     - The user rating
 * @property {Number}        [rating]                         - The result category
 * @property {Object[]}      [locations]                      - The result locations
 * @property {String}        [mostBookedInDestinationText]    - Most booked text
 * @property {String[]}      [urgencyMessages]                - Urgency messages
 * @property {String}        [departureDateDetail]            - Departure date in human readable format
 * @property {String[]}      [featuredFilters]                - Featured applied filters to get a package offer
 *
 * @property {Object}        [price]                          - Result price object
 * @property {Object}        [price.yieldCampaignLogo]        - Price > Yield campaign logo
 * @property {String}        [price.yieldCampaignLogo.src]    - Price > Yield campaign logo src
 * @property {String[]}      [price.includedServices]         - Price > Included services
 * @property {Number}        [price.averagePrice]             - Price > main price to be displayed
 * @property {Object}        [price.currencySettings]         - Price > currencySettings
 * @property {String}        [price.currencySettings.symbol]  - Price > currencySettings > symbol
 * @property {String}        [price.currencySettings.symbolPosition]  - Price > currencySettings > symbolPosition (BeforeAmount, AfterAmount)
 * @property {Object}        [price.priceDiscounts]           - Price > appliedDiscounts
 * @property {String}        [price.priceDiscounts.discountPercentage]  - Price > appliedDiscounts > percentage
 * @property {String}        [price.priceDiscounts.originalPrice]  - Price > appliedDiscounts > Previous public price
 * @property {Number}        [price.publicPrice]              - Price > Previous public price
 * @property {Object}        [price.acmInformation]           - Price > ACM info
 * @property {String}        [price.acmInformation.acmUrl]    - Price > ACM info > API url
 * @property {Object}        [attributes]                     - Attributes for the search result
 */

// Import tokens
const tokensShared = require('./data/c-search-result__tokens.json')['c-search-result']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/search-result/data/c-search-result__tokens.json`)['c-search-result']; // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultSearchResultData = {
  id: '',
  isFavorite: false,
  isUnavailable: false,
  isFlightOnly: false,
  isHighlighted: false,
  images: [],
  name: '',
  usps: [],
  urlWithFilters: '',
  extraClasses: '',
  collection: null,
  attributes: ''
}

export const SearchResultTemplate = (d, staticTexts, useProductCardFooter = false) => {
  const collection = getProcessedCollectionDataFromItem(d)
  d = { ...defaultSearchResultData, ...d, collection }

  const origin = d.map ? 'map' : 'search-result'
  const featuredFilters =
    d.isUnavailable || !d.featuredFilters
      ? undefined
      : {
          mealplan: d.featuredFilters.mealplan,
          occupancy: d.featuredFilters.occupancy,
          departureDate: d.departureDateDetail,
          duration: d.featuredFilters.duration
        }

  return `
<div
  class="c-search-result
  ${d.isUnavailable ? 'c-search-result--is-unavailable' : ''}
  ${d.isFlightOnly ? 'c-search-result--is-flight-only' : ''}
  ${d.isHighlighted ? 'c-search-result--is-highlighted' : ''}
  ${getTokenClass('variant', d.variant ? d.variant : 'default', tokens)}
  ${d.extraClasses ? d.extraClasses : ''}"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}
  ${d.id && `data-acco-id="${d.id}"`}
  ${
    d.highestRatedCategory
      ? `data-highest-rated-category-name='${d.highestRatedCategory.name}' data-highest-rated-category-rating='${d.highestRatedCategory.rating}'`
      : ''
  }>
    <div class="c-search-result__content">
        <div class="c-search-result__header">
            <div class="c-search-result__image-wrapper">
                <button
                  class="m-button--clean c-favorite-btn c-search-result__favourite ${d.isFavorite ? 'is-active' : ''}"
                  data-js-component="c-favorite-btn" data-favorite__item-id="${d.id}"
                  data-favorite__parent-contextId="${d.parentContextItemId}"
                  ${d.publicationCode ? `data-favorite__publication-code="${d.publicationCode}"` : ''}
                >
                </button>
                ${
                  Array.isArray(d.images) && d.images.length > 0
                    ? d.variant === 'condensed-tiny'
                      ? `${getImageTemplate(d.images[0], d.urlWithFilters + '&originList=' + origin, d.name)}`
                      : `${SliderTemplate({
                          luminosity: getTokenClass('luminosity', d.luminosity ? d.luminosity : 'default', tokens),
                          items: getSliderItems(d.images, d.urlWithFilters + '&originList=' + origin, d.name),
                          attributes: { 'data-arrow-keys': false }
                        })}`
                    : ''
                }
                ${
                  d.collection
                    ? `${LabelTemplate({
                        text: d.collection.text,
                        collection: d.collection.id,
                        extraClasses: 'c-search-result__collection'
                      })}`
                    : ''
                }
            </div>
        </div>
        <div class="c-search-result__body sp-stack-children--tiny">
            ${
              d.userRating
                ? `<div class="c-number-score c-number-score--bubbled c-search-result__score">
                  <span class="c-number-score__label">${staticTexts.userRating}</span>
                  <div class="c-number-score__body">
                    <span class="c-number-score__grade">${Math.round(d.userRating * 10) / 10}</span>
                  </div>
                </div>`
                : ''
            }
            ${
              d.rating
                ? `<span data-rating="${d.rating}" class="c-rating c-rating--s c-search-result__rating"></span>`
                : ''
            }
            <h2 class="c-search-result__name m-heading ${getTokenClass(
              'name__size',
              d.variant ? d.variant : 'default',
              tokens
            )}">${d.name}</h2>
            ${
              d.locations
                ? BreadcrumbsTemplate({
                    ...defaultBreadcrumbsData,
                    ...{
                      items: d.locations.map(location => ({ text: location.name, unclickable: location.unclickable })),
                      extraClasses: 'c-search-result__breadcrumbs'
                    }
                  })
                : ''
            }
            ${
              d.mostBookedInDestinationText
                ? `<div class="c-search-result__mostbooked"><i class="c-search-result__mostbooked-icon m-icon--warning-circle"></i>${d.mostBookedInDestinationText}</div>`
                : ''
            }
              ${d.isMarketExclusive ? customUSP(staticTexts.sunwebOnly, 'spark') : ''}
              ${d.isSustainable ? customUSP(staticTexts.sustainable, 'sustainable') : ''}
              ${d.campaign ? campaignUSP(d.campaign, d.campaignUrl) : ''}
            <ul class="c-bullet-list o-list-bare c-search-result__usps">
              ${d.usps
                .map(
                  usp =>
                    `<li class="c-bullet-list__item"><span class="c-bullet-list__icon m-icon--checkmark o-bullet__icon"></span><span class="c-bullet-list__text o-bullet__text o-bullet__text--middle">${usp}</span></li>`
                )
                .join('')
                .trim()}
            </ul>
            ${
              d.urgencyMessages
                ? `<ul class="c-bullet-list o-list-bare c-search-result__urgency-messages">
                  ${d.urgencyMessages
                    .map(
                      urgencyMessage => `
                  <li class="c-bullet-list__item"><span class="c-bullet-list__icon m-icon--fire o-bullet__icon"></span> <span class="c-bullet-list__text o-bullet__text o-bullet__text--middle">${urgencyMessage}</span></li>
                  `
                    )
                    .join('')
                    .trim()}
                </ul>`
                : ''
            }
              ${
                d.price && d.price.yieldCampaignLogo && d.price.yieldCampaignLogo.src && useProductCardFooter
                  ? `<img class="c-search-result__yield " src="${d.price.yieldCampaignLogo.src}">`
                  : ''
              }
        </div>
    </div>
    ${
      useProductCardFooter
        ? getFooter(d, featuredFilters, staticTexts, defaultButtonData, defaultPriceData)
        : `
       <div class="c-search-result__footer">
      ${
        d.isUnavailable
          ? `
          <div class="c-search-result__unavailable-info">
            <div class="c-search-result__unavailable-title m-body m-body--small">${staticTexts.unAvailableTitle}</div>
            <div class="c-search-result__unavailable-text  m-body m-body--small">${staticTexts.unAvailableText}</div>
          </div>
          ${BtnTemplate({
            ...defaultButtonData,
            variant: 'default',
            text: staticTexts.subscribe,
            link: {
              href: d.urlWithFilters + '&originList=' + origin,
              title: staticTexts.subscribe
            },
            extraClasses: 'c-search-result__cta c-search-result__unavailable-button'
          })}
        `
          : `
        ${
          d.price
            ? `
          <div class="c-search-result__footer-top">
            ${
              d.price.yieldCampaignLogo && d.price.yieldCampaignLogo.src
                ? `<img class="c-search-result__yield" src="${d.price.yieldCampaignLogo.src}">`
                : ''
            }
            ${PackageBlockTemplate({
              ...defaultPackageBlockData,
              extraClasses: 'c-search-result__package-block',
              includedServices:
                !d.price.includedServices || Array.isArray(d.price.includedServices) // Falsy value or array?
                  ? d.price.includedServices // Pass as-is.
                  : Object.keys(d.price.includedServices), // Extract the keys as an array.
              departureDate: d.departureDateDetail,
              arrivalDate: d.arrivalDateDetail,
              filters: Array.isArray(d.featuredFilters) ? d.featuredFilters : Object.values(d.featuredFilters),
              outboundDepartureAirportName: d.outboundDepartureAirportName
            })}
          </div >`
            : ''
        }

          <div class="c-search-result__footer-bottom">
            ${
              d.price
                ? `
              ${PriceTemplate({
                ...defaultPriceData,
                size: d.variant === 'condensed-tiny' ? '' : 'large',
                extraClasses: 'c-search-result__price',
                value: d.price.averagePrice,
                currency: d.price.currencySettings.symbol,
                currencyPosition: d.price.currencySettings.symbolPosition === 'BeforeAmount' ? 'before' : 'after',
                saleText:
                  d.price.priceDiscounts && d.price.priceDiscounts.discountPercentage
                    ? `-${d.price.priceDiscounts.discountPercentage}%`
                    : d.price.publicPrice && d.price.publicPrice.discountPercentage
                    ? `-${d.price.publicPrice.discountPercentage}%`
                    : undefined,
                oldPrice:
                  d.price.priceDiscounts && d.price.priceDiscounts.originalPrice
                    ? d.price.priceDiscounts.originalPrice
                    : undefined,
                acmUrl:
                  d.price.acmInformation && d.price.acmInformation.acmUrl ? d.price.acmInformation.acmUrl : undefined,
                acmModalId: d.price.acmInformation && d.price.acmInformation.acmUrl ? 'w-search__modal' : undefined,
                acmHideInfoIcon: !!d.price?.mandatoryExtraCostsText,
                legend: staticTexts.priceLegend,
                publicPrice: d.price.publicPrice
                  ? `${staticTexts.publicPrice} ${d.price.publicPrice.price} ${d.price.currencySettings.symbol}`
                  : undefined,
                discount:
                  d.price.priceDiscounts && d.price.priceDiscounts.originalPrice
                    ? d.price.priceDiscounts.originalPrice - d.price.averagePrice
                    : undefined,
                discountText: staticTexts.discountSaveText,
                publicPriceDetail: d.price.skiPassPublicPriceText,
                label: d.price?.priceDiscounts?.description,
                label2:
                  d.price?.mandatoryExtraCostsText && d.price.acmInformation
                    ? getACMPriceLabel({
                        ...d,
                        text: d.price.mandatoryExtraCostsText,
                        staticText: d.price.staticText,
                        acmUrl: d.price.acmInformation.acmUrl,
                        modalId: 'w-search__modal'
                      })
                    : d.price.staticText,
                skipassLabel: d.price?.includedServices?.skipass,
                labelGrayed: true
              })}`
                : ''
            }

            ${BtnTemplate({
              ...defaultButtonData,
              variant: 'flow',
              text: staticTexts.button,
              link: {
                href: d.urlWithFilters + '&originList=' + origin,
                title: staticTexts.button
              },
              extraClasses: 'c-search-result__cta'
            })}

            ${BtnTemplate({
              ...defaultButtonData,
              variant: 'flow',
              text: staticTexts.button,
              link: {
                href: d.urlWithFilters + '&originList=' + origin,
                title: staticTexts.button,
                target: '_blank'
              },
              extraClasses: 'c-search-result__cta'
            })}

            ${
              d.price && d.price.onRequest
                ? `<div class="c-search-result__warning-message">${staticTexts.onRequest}</div>`
                : ''
            }
          </div>`
      }
    </div>
       `
    }
    <a class="c-search-result__link" href="${d.urlWithFilters + '&originList=' + origin}"></a>
    <a class="c-search-result__link" href="${d.urlWithFilters + '&originList=' + origin}" target="_blank"></a>
</div>
`
}

function getSliderItems (images, urlWithFilters, productName) {
  return images.map(i => getImageTemplateForSlider(i, urlWithFilters, productName))
}

function getParametersToRescaleImage () {
  return 'width=320&height=240&scale=both&mode=crop'
}

function getImageTemplateForSlider (imageUrl, urlWithFilters, productName) {
  const ratio = 'o-ratio--16:9 o-ratio--4:3@sml'

  const getImageTemplate = () => `
  <div class="c-img o-ratio ${ratio} c-search-result__image" data-img-src="${imageUrl}?width={W}&height={H}&scale=both&mode=crop" data-js-component="c-img" data-c-img__resolve="intersect">
  <img src="${imageUrl}?${getParametersToRescaleImage()}" class="c-img__placeholder o-ratio__content" alt="${productName}">
</div>`

  return `
    <a href="${urlWithFilters}" target="_blank" class="c-search-result__image-link">
    ${getImageTemplate()}
    </a>
    <a href="${urlWithFilters}" class="c-search-result__image-link">
    ${getImageTemplate()}
    </a>`
}

function getImageTemplate (imageUrl, urlWithFilters, productName) {
  const imageUrlRescaled = imageUrl + '?' + getParametersToRescaleImage()

  return `
  <a href="${urlWithFilters}">
    ${ImgTemplate({
      resolvedSrc: imageUrlRescaled,
      sync: true,
      ratio: '1:1',
      title: productName
    })}
  </a>
  `
}

const campaignUSP = (campaign, url) => {
  const { title, icon, highlightedWord } = campaign

  const bulletIcon = icon || 'tag'
  const modifiedTitle = highlightedWord ? title.replace(highlightedWord, `<b>${highlightedWord}</b>`) : title
  return `<div><button type="button" class="c-search-result__campaign_btn" data-js-component="c-btn" data-c-modal__action="open" data-c-modal__source="${url}" data-c-modal__id="w-search__modal"><span class="c-search-result__campaign_icon m-icon--${bulletIcon}"></span>${modifiedTitle}<i class="c-search-result__action-icon m-icon--information-circle"></i></button></div>`
}

const customUSP = (text, icon) => {
  return `
    <div class="c-search-result__custom-usp">
      <span class="m-icon m-icon--${icon}"></span>
      <span class="c-search-result__custom-usp-text">${text}</span>
    </div>
  `
}
